import React, { useState } from 'react';
import ImageModal from './ImageModal';
import { siteConfig } from '../config';

export default function SectionC() {
  const [selectedImage, setSelectedImage] = useState(null);
  const { about } = siteConfig;
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalOpen(false);
  };

  return (
    <section data-aos="fade-up" data-aos-duration="1000" className="bg-white">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-16 lg:py-32">
        <header className="pb-6 border-b border-gray-200">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">
            {about.sectionTitle}
          </h1>
        </header>

        {/* Image and Text Section */}
        <div className="mt-10 grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
          {/* Image Section */}
          <div>
            <img
              src={about.image.src}
              alt={about.image.alt || `A highlight of Alfredo Rubinstein's real estate expertise`}
              className="w-full max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 cursor-pointer"
              width={about.image.width}
              height={about.image.height}
              onClick={() => openModal(about.image.src)}
            />
          </div>

          {/* Text Section */}
          <div>
            <p className="text-lg leading-7 text-gray-600">
              {about.description}
            </p>
          </div>
        </div>

        {/* Image Modal */}
        {isModalOpen && selectedImage && (
          <ImageModal 
            isOpen={isModalOpen}
            onClose={closeModal}
            imgSrc={selectedImage}
            width={about.image.width}
            height={about.image.height}
            alt={about.image.alt || `Detailed view of Alfredo Rubinstein's real estate expertise`}
          />
        )}
      </div>
    </section>
  );
}
