import React, { useState } from 'react';
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube, FaTiktok } from "react-icons/fa";
import { siteConfig } from '../config';

export default function SectionE() {
  const { contact, theme } = siteConfig;
  const { primaryColor, secondaryColor, accentColor } = theme;

  const [formData, setFormData] = useState({ name: '', service: '', message: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, service, message } = formData;
    const subject = encodeURIComponent(`Service Request: ${service}`);
    const body = encodeURIComponent(
      `Name: ${name}\nService Requested: ${service}\nMessage:\n${message}`
    );

    window.location.href = `mailto:${contact.emailRecipient}?subject=${subject}&body=${body}`;
  };

  return (
    <div data-aos="fade-up" className="bg-white w-full min-h-screen flex items-center justify-center">
      <section className="max-w-6xl w-full px-6 py-12 sm:py-20 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16">
          
          {/* Contact Form Section */}
          <div aria-labelledby="contact-form">
            <h3 id="contact-form" className={`text-2xl font-semibold text-${secondaryColor} mb-6`}>
              Contact Us
            </h3>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="flex flex-col">
                <label htmlFor="name" className={`text-sm font-medium text-${secondaryColor}`}>Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className={`mt-1 px-3 py-2 border rounded-md text-${secondaryColor} focus:outline-none focus:ring focus:border-${primaryColor}`}
                  aria-label="Your name"
                />
              </div>
              
              <div className="flex flex-col">
                <label htmlFor="service" className={`text-sm font-medium text-${secondaryColor}`}>Service Requested</label>
                <input
                  type="text"
                  id="service"
                  name="service"
                  value={formData.service}
                  onChange={handleChange}
                  required
                  className={`mt-1 px-3 py-2 border rounded-md text-${secondaryColor} focus:outline-none focus:ring focus:border-${primaryColor}`}
                  aria-label="Service you are requesting"
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="message" className={`text-sm font-medium text-${secondaryColor}`}>Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  rows="4"
                  className={`mt-1 px-3 py-2 border rounded-md text-${secondaryColor} focus:outline-none focus:ring focus:border-${primaryColor}`}
                  aria-label="Your message"
                ></textarea>
              </div>

              <button
                type="submit"
                className={`w-full py-2 px-4 bg-orange-500 text-white font-semibold rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2`}
                aria-label="Send your message"
              >
                Send Message
              </button>
            </form>
          </div>

          {/* Contact Details Section */}
          <div className="text-center lg:text-left" aria-labelledby="contact-details">
            <img
              className="mx-auto lg:mx-0 h-20 mb-4"
              src={contact.logo}
              alt="Business Logo"
            />
            <h3 id="contact-details" className={`text-2xl font-semibold text-${secondaryColor} mb-2`}>
              {contact.sectionTitle}
            </h3>
            <p className={`text-${accentColor} mb-8`}>{contact.description}</p>
            
            <div className="flex flex-col items-center lg:items-start space-y-2">
              
              {/* Social Media Links */}
              <div className="flex items-center space-x-4 mt-4" aria-label="Social media links">
                <a href={contact.socialLinks.facebook} target="_blank" rel="noopener noreferrer" className={`text-${primaryColor}`} aria-label="Facebook">
                  <FaFacebook size={24} />
                </a>
                <a href={contact.socialLinks.instagram} target="_blank" rel="noopener noreferrer" className={`text-${accentColor}`} aria-label="Instagram">
                  <FaInstagram size={24} />
                </a>
                <a href={contact.socialLinks.twitter} target="_blank" rel="noopener noreferrer" className="text-blue-500" aria-label="Twitter">
                  <FaTwitter size={24} />
                </a>
                <a href={contact.socialLinks.youtube} target="_blank" rel="noopener noreferrer" className="text-red-600" aria-label="YouTube">
                  <FaYoutube size={24} />
                </a>
                <a href={contact.socialLinks.tiktok} target="_blank" rel="noopener noreferrer" className="text-black" aria-label="TikTok">
                  <FaTiktok size={24} />
                </a>
              </div>

              {/* Contact Numbers */}
              <div className="mt-6" aria-label="Contact numbers">
                {contact.contactNumbers.map((number, index) => (
                  <p
                    key={index}
                    className={`font-bold ${index === 0 ? 'text-orange-400' : `text-${primaryColor}`}`}
                  >
                    <a href={`tel:${number}`} aria-label={`Call us at ${number}`}>{number}</a>
                  </p>
                ))}
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
