// import React from 'react';
// import { siteConfig } from '../config';

// export default function Footer() {
//   const { businessDetails, footer } = siteConfig;
  
//   return (
//     <footer className="py-4" style={{ backgroundColor: '#FF851A' }}>
//       <div className="container mx-auto flex text-black items-center justify-between text-white px-4">
        
//         <p className="text-sm md:text-base">
//           © 2024 {businessDetails.name}
//         </p>
        
//         <a
//           href="https://mybizsolutions.us"
//           target="_blank"
//           rel="noopener noreferrer"
//           className="flex items-center space-x-2"
//         >
//           <span className="text-sm">Powered by: </span>
//           <img
//             src={footer.bizSolutionsLogo}
//             alt="BizSolutions Logo"
//             className="h-6 w-auto"
//           />
//           <span className="text-sm">BizSolutions LLC</span>
//         </a>

//       </div>
//     </footer>
//   );
// }

import React from 'react';
import { siteConfig } from '../config';

export default function Footer() {
  const { businessDetails, footer } = siteConfig;

  return (
    <footer 
      className="py-6 bg-orange-500 text-white"
      aria-labelledby="footer-heading"
    >
      <div className="container mx-auto px-4 md:px-6 lg:px-8">
        
        {/* Footer Content */}
        <div className="flex flex-col md:flex-row items-center justify-between">
          
          {/* Business Name and Year */}
          <p className="text-sm md:text-base text-center md:text-left">
            &copy; {new Date().getFullYear()} {businessDetails.name}. All rights reserved.
          </p>

          {/* Powered By BizSolutions */}
          <a
            href="https://mybizsolutions.us"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center space-x-2 mt-4 md:mt-0"
            aria-label="Powered by BizSolutions LLC"
          >
            <span className="text-sm">Powered by:</span>
            <img
              src={footer.bizSolutionsLogo}
              alt="BizSolutions LLC Logo"
              className="h-6 w-auto"
            />
            <span className="text-sm">BizSolutions LLC</span>
          </a>
        </div>

      </div>
    </footer>
  );
}
